import React from 'react';
import {Container, Image} from "react-bootstrap";
import logo from "../assets/pldt_logo.jpg";


class NavHeader extends React.Component {
    render() {
        return (
            <>
                <div className="client_secondary_bg">
                    <Container height="70px">
                        <div className="text-center">
                            <a href="/">
                                <Image height="225px" id="logo2" style={{padding: "5px"}} src={logo}/>
                            </a>
                        </div>
                    </Container>
                </div>
                <hr className="client_primary_border-color mt-0 mb-0"/>
            </>
        );
    }
}
export default NavHeader;