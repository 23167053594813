import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/system_main.css';
import {Col, Container, Image, Row} from "react-bootstrap";
import NavHeader from './components/NavHeader'
import Greeting from "./components/Greeting";
import sblogo from './assets/sb_caramel_frappe.jpg'
import ajaxgif from './assets/ajax-loader.gif'
import QRCode from "react-qr-code";

class App extends React.Component {

    getbcode() {
        const params = new URLSearchParams(this.props.location.search)
        return params.get("bcode")
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            code_status: ""
        };
    }

    componentDidMount() {
        var bcode = this.getbcode()

        if(bcode == null){
            this.setState({
                isLoaded: true,
                code_status: ""
            });
        } else {
            console.log("Fetching")
            fetch("https://ogmdms6jni.execute-api.ap-southeast-1.amazonaws.com/default/globe/check?bcode=" + bcode)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            code_status: result
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    render() {
        const { error, isLoaded, code_status} = this.state;
        // console.log(code_status.status)

        if(this.getbcode() != null){
            return (
                <div className="bcodeRedemption">
                    <NavHeader/>
                    <Container className='component2'>

                        {code_status.status === "Valid" ?
                            <Row>
                                <Col md={{span: 4, offset: 4}} xs={{span: 10, offset: 1}}>
                                    <Row className='my-4'>
                                        <Col xs={{span: 6}}>
                                            <Image id="logo" src={sblogo}/>
                                        </Col>
                                        <Col xs={{span: 6}} className="text-center element_center" style={{overflow: 'hidden'}}>
                                            <div className="my-4" id="loader" style={{display: 'none'}}>
                                                <Image src={ajaxgif}/>
                                                <p>Loading coupon... Please wait</p>
                                            </div>

                                            <div id="content">

                                                {error ? <h5>error.message</h5>: null }
                                                {!isLoaded ? <h5>Loading...</h5>: null }
                                                {isLoaded &&
                                                    <div className="d-flex">
                                                        <div id="qrcode" title={code_status.code}>
                                                            <QRCode value={this.getbcode()} size={110} style={{display: 'block'}}/>
                                                        </div>
                                                    </div> }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Greeting/>
                            </Row>

                            :

                            <Row>
                                <Col md={{span: 4, offset: 4}} xs={{span: 10, offset: 1}}>
                                    <Row className='my-4'>
                                        <Col className="text-center">
                                            <div id="invalidContent">
                                                {error ? <h3>error.message</h3>: null }
                                                {!isLoaded ? <h3>Loading...</h3>: null }
                                                {isLoaded ?
                                                        (code_status.status.toLowerCase().includes('expired')) ?
                                                            <h3>This code is expired</h3> :
                                                            (code_status.status.toLowerCase().includes('invalid')) ?
                                                                <h3>Invalid Code</h3> :
                                                                (code_status.status.toLowerCase().includes('claimed')) ?
                                                                    <h3>This code has been claimed</h3> :
                                                                    <h3>{code_status.status}</h3>
                                                    : null }
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }

                    </Container>
                </div>
            );
        } else {
            return (
                <div className="bcodeRedemptionForm">
                    <NavHeader/>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4 cold-md-6 offset-md-3 text-center" id="redemptionFormContainer">
                                <div className="mt-4 p-4 card">
                                    <form id="redeemForm">
                                        <h5>Enter code to redeem <br/> your Starbucks Treat</h5>
                                        <div className="form-group">
                                            <input className="form-control text-center" type="text" name="bcode"  placeholder="XXXXXXXXXX"/>
                                        </div>
                                        <button className="btn-block client_primary_bg-color client_secondary_color p-2" type="submit"> Claim </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default App;
